import { template as template_798f46f346eb42ed8c49ad7c996d8d33 } from "@ember/template-compiler";
const FKControlMenuContainer = template_798f46f346eb42ed8c49ad7c996d8d33(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
