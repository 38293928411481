import { template as template_8e9c992f3bc24d4081fd18231e9e40b3 } from "@ember/template-compiler";
const FKText = template_8e9c992f3bc24d4081fd18231e9e40b3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
